import styled from "styled-components";

export const Container = styled.div`
background-color: #115c9b;
height: 30vh;
width: 100%;
display: flex;
flex-direction: column;

p {
    text-align: center;
    color: #fff;
}

span {
    width: 100%;
    height: 2rem;
}

.wrapper {
    width: 100%;
    height: 95%;
    padding: 0px 70px;
    box-sizing: border-box;
    background-color: #1f76bd;
    display: flex;
    align-items: center;
}

.left, .center, .right {
    flex: 1;
    height: 40%;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon, a {
    font-size: 2rem;
    margin: 10px;
    color: #a5a0a0;
    transition: all 0.7s ease;
}

.icon:hover, a:hover {
    color: #fff;
    transform: scale(1.2,1.2);
    transition: all 0.7s ease;
}
`