import React from "react";
import { Container } from "./AboutContentElements";
import about from "../../assets/images/about.webp";

const AboutContent = () => {
  return (
    <Container>
      <div className="left">
        <img src={about} alt="about-us" />
      </div>
      <div className="right">
        <h2>ABout Rhafi

            <span></span>
        </h2>
        <p>
        The Rural Health Aid Foundation International (RHAFI) is a Non-Profit Organization that was established in 2011 and aims at providing medical assistance to the elderly and children in rural areas of Ghana. The founder and president; O’Brien K. Awuah, who has a passion for the health sector, realized that the elderly and children - especially those in the rural areas - who are most vulnerable to illnesses and easily lose their lives when sick, need frequent medical attention.  However, financial constraints besides transportation impede the rate at which these groups of people seek medical attention.
        </p>
        <p>
        Malaria, tuberculosis, cholera, dental and eye problems, and other infectious diseases frequently attack the aforementioned groups rendering them handicapped and making life unbearable for them. 
        </p>
      
        <p>
        In order to bring relief to these unfortunate people and save precious lives, RHAFI has been established to give a helping hand by providing free medical assistance. Volunteers comprise health professionals, teachers, students, religious leaders, and musicians, both in Ghana and abroad. All other professions are welcome to join in the work geared towards our goal. 
        </p>

        <p>
        Currently, RHAFI has been incorporated in New York State, USA, as a nonprofit organization, serving needy communities as well. We are looking for partners from all over the world to join us to provide humanitarian services in Ghana and abroad.
        </p>
      </div>
    </Container>
  );
};

export default AboutContent;
