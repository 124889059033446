import React from 'react'
import styled from 'styled-components'
import { MdCloudUpload } from 'react-icons/md'

const AddGallery = () => {
  return (
    <AddGalleryWrap>
          <h1>Upload Gallery</h1>
          <div className='upload-wrap'>
              <input type="file" id='file' style={{display: 'none'}} />   
              <label htmlFor="file">
                  <p>Click to here upload</p>
                  <span>
                      <MdCloudUpload className='cloud' />
                  </span>
              </label>
          </div>
    </AddGalleryWrap>
  )
}

const AddGalleryWrap = styled.div`
 h1{
    font-size: 40px;
    text-align: center;
    padding: 10px 0;
 }

 .upload-wrap{
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
 
    label{
        background: #ffff;
        border-radius: 10px;
        cursor: pointer;
        width: 80%;
        height: 300px;
        padding: 40px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;

        span{
            margin-top: 20px;

            .cloud{
                font-size: 40px;
                color: rgba(0, 0, 0, 0.575);
            }
        }
    }
 }
`

export default AddGallery