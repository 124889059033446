import styled from "styled-components";

export const Container = styled.div`
    width: 28%;
    height: fit-content;
    position: relative;
    background-color: #fff;
    transition: all .6s ease-in-out;
    margin: 2rem;

    
  &:hover {
    box-shadow: 1px 1px 19px #a1a1a1;
    border-radius: 10px;
    transition: all .6s ease-in-out;
  }

  &:hover img {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

    img {
        width: 100%;
        height: 200px;
    }

    .date {
        display: flex;
        width: 100%;
        margin: 20px;
        color: #bebebe;
    }
    .text {
        padding: 10px;
        box-sizing: border-box;
    }

    .icon {
        margin-right: 20px;
    }

    h3 {
        font-size: 1.4rem;
        margin: 20px;
    }

    .brief {
        font-size: 1.3rem;
        width: 100%;
        color: #919191;
        margin-top: 1rem;
    }

    button {
        border: 1px solid #1f76bd;
        background-color: transparent;
        padding: 10px 16px;
        font-size: 1.4rem;
        margin: 0px 0px 0px 20px;
        position: absolute;
        bottom: 1rem;
        transition: all .6s ease-in-out;
    }

    button:hover {
        background-color: #1f76bd;
        color: #fff;
        border: none;
        transition: all .6s ease-in-out;
    }

    @media screen and (max-width: 840px) {
        width: 45%;
        height: fit-content;
        margin-bottom: 2rem;
    }

    @media screen and (max-width: 430px) {
        width: 90%;
        height: fit-content;
    }
`