import React from 'react'
import { Container, Wrapper } from './ContactCardElements'
import { FaEnvelope, FaMapMarker, FaPhone, FaWhatsapp } from 'react-icons/fa'

const ContactCard = () => {
  return (
    <Container>
        <Wrapper>
          <p className="name">Mr. Awuah</p>
          <p> <FaPhone className='icon' />+1 9178195371 </p>
          <p> <FaEnvelope className='icon' /> awuah371@rhafi.org </p>
          <p> <FaMapMarker className='icon'/> Unite States </p>
        </Wrapper>
        <Wrapper>
          <p className='name' > Ms. Ferlody Agyemang</p>
          <p> <FaPhone className='icon' /> +233(0) 243129656</p>
          <p> <FaEnvelope className='icon' /> Not Available </p>
          <p> <FaMapMarker className='icon'/> Accra,Ghana </p>
        </Wrapper>
        <Wrapper>
          <p className="name">Ms. Anastasia Abena Afrah</p>
          <p> <FaPhone className='icon' /> +233(0) 20285166 </p>
          <p> <FaEnvelope className='icon' /> Not Available </p>
          <p> <FaMapMarker className='icon'/> Kumasi, Ghana </p>
        </Wrapper>
        <Wrapper>
          <p className="name"> Mr. ignatius Awuah Acheampong</p>
          <p> <FaPhone className='icon' /> +233(0) 200656939 </p>
          <p> <FaEnvelope className='icon' /> Not Available </p>
          <p> <FaMapMarker className='icon'/> Brong, Ghana </p>
        </Wrapper>
    </Container>
  )
}

export default ContactCard