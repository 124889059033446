import React, { useState } from "react";
import { Container, Links } from "./MainNavElements.jsx";
import { FaBars, FaTimes } from "react-icons/fa";
import logo from '../../assets/images/logo.png'
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const MainNav = () => {
  const [res, setRes] = useState(true);
  const navigate = useNavigate();

  return (
    <Container>

     <div className="wrapper">
     <h1 className="logo-div"> <Link to="/" className="logo" ><img src={logo} alt="logo" /></Link>  </h1>
        <div className="links">
          <ul>
            <li>
            <Links to="/" >Home</Links>
            </li>
            <li>
              <Links to="/about-us" >About</Links>
            </li>
            {/* <li>
              <Links to="/our-causes" >Causes</Links>
            </li> */}
            <li>
              <Links to="/our-events" >Events</Links>
            </li>
            <li>
              <Links to="/contact-us" >Contact</Links>
            </li>
            <li>
              <Links to="/gallery" >gallery</Links>
            </li>
          </ul>
        </div>
        <button onClick={()=> {
          navigate("/donate")
        }} >Donate</button>
        <div
        className="res"
        onClick={() => {
          setRes(!res);
        }}
      >
        {res ? <FaBars /> : <FaTimes />}
      </div>
     </div>
        
    
      


      {res ? "" : <div className="responsive">
        <ul>
          <li>
            <Links to="/" onClick={() => {
          setRes(!res);
        }} >Home</Links>
          </li>
          <li>
            <Links to="/about-us" onClick={() => {
          setRes(!res);
        }} >About</Links>
          </li>
          {/* <li>
            <Links to="/our-causes" onClick={() => {
          setRes(!res);
        }} >Causes</Links>
          </li> */}
          <li>
      
            <Links to="/our-events" onClick={() => {
          setRes(!res);
        }}>Events</Links>
          </li>
          <li>
      
            <Links to="/gallery" onClick={() => {
          setRes(!res);
        }}>Gallery</Links>
          </li>
          <li>
            <Links to="/contact-us" onClick={() => {
          setRes(!res);
        }}>Contact</Links>
          </li>
        </ul>
        <button onClick={()=> {
          navigate("/donate")
          setRes(!res);
        }} >Donate</button>
      </div>}
      
      
      
       
    </Container>
  );
};

export default MainNav;
