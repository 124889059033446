import React from "react";
import RecentCard from "../RecentCard/RecentCard";
import { Container } from "./RecentElements";

const Recent = () => {
  return (
    <Container>
      <h3 className="head">
        Recent Events <span></span>
      </h3>
      <p>
        Help today because tomorrow you may be the one who needs more helping!
      </p>
      <div className="wrapper">
       <RecentCard />
      </div>
  
    </Container>
  );
};

export default Recent;
