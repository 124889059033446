import styled from "styled-components";

export const Container = styled.div`
width: 100%;
height: fit-content;
display: flex;
justify-content: center;
align-items: center;
padding: 20px 70px;
box-sizing: border-box;
flex-wrap: wrap;


@media screen and (max-width: 430px) {
        padding: 10px 10px;
    }
`

export const Wrapper = styled.div`
    width: 60%;
    height: 50vh;
    background-color: beige;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;

    p {
        font-size: 2rem;
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon {
        margin-right: 15px;
        color: #115c9b;
    }

    @media screen and (max-width: 430px) {
        width: 90%;
        
        p {
            font-size: 1.5rem;
        }

    }

    @media screen and (max-width: 320px) {
        width: 90%;
        
        p {
            font-size: 1.3rem;
        }

    }
`