import React from 'react'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

const SliderContent = ({ activeIndex, imageSlider}) => {
  const navigate = useNavigate();
  return (
   <section className='slider-container' >
     { imageSlider.map((slide, index) => (
        <div className={index === activeIndex ? "slides active" : "inactive"} key={index} >
            <img src={slide.src} alt="img" className="slide-img" />
            <motion.h2 animate={{ scale: 1, opacity: 1, y: 0 }} initial={{ scale: 0, y: -500, opacity: 0 }} transition={{
    type: "spring",
    // stiffness: 660,
    // damping: 20,
    duration: 2
  }}  className='slide-title' >{slide.title}</motion.h2>
            <motion.h3 animate={{ x: 0 }} initial={{ x: -1000 }}    transition={{ type: 'spring', duration: 2 }}className='slide-text' >{slide.description}</motion.h3>
            <motion.button onClick={()=> {
              navigate("/donate")
            }} animate={{ scale: 1, opacity: 1 }} initial={{ scale: 0, opacity: 0 }} transition={{ type: 'tween', duration: 0.8}} className='slide-btn' > {slide.btn} </motion.button>
        </div>
     ))}
   </section>
  )
}

export default SliderContent
