import { Routes, Route } from 'react-router-dom'
import MainAbout from './pages/MainAbout';
import Footer from './components/Footer/Footer';
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home";
import Donate from './pages/Donate';
import Events from './pages/Events';
import ContactUs from './pages/ContactUs';
import Gallery from './pages/Gallery'
import Dashboard from './pages/Dashboard/DashboardPage';
import AddGallery from './components/AddGallery/AddGallery';

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about-us" element={<MainAbout />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/our-events" element={<Events />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/ad-rhafi-port" element={<Dashboard />} />
        <Route path="/ad-rhafi-port/upload-gallery" element={<AddGallery />} />
      </Routes>
    </>

  )
}

export default App

