import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 40px 70px;
  box-sizing: border-box;
  background-color: #cbcbcb65;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .head {
    font-size: 2.3rem;
    text-transform: uppercase;
    color: #1f76bd;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .head span {
    border-bottom: 2px solid #1f76bd;
    width: 100px;
    margin: 10px auto 0px;
  }

  .head::after {
    content: "";
    display: block;
    width: 350px;
    height: 1px;
    background-color: #1f76bd;
    margin: 0px auto;
    color: #1f76bd;
    margin-top: -2px;
  }

  p {
    text-align: center;
    font-size: 1.4rem;
    width: 50%;
    margin: 20px auto;
  }

  .wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
  }

  @media screen and (max-width: 840px) {
    height: fit-content;

    .wrapper {
      flex-wrap: wrap;
    }

    p {
      width: 70%;
    }
  }

  @media screen and (max-width: 430px) {
    padding: 20px 10px;
    .head {
      font-size: 1.3rem;
    }

    .head::after {
      width: 250px;
    }

    p {
      font-size: 1rem;
    }
  }

  .wrapper {
    justify-content: center;
  }

@media screen and (max-width: 320px) {
  .head span {
    width: 60px;
  }

  .head::after {
    width: 150px;
  }

  p {
    width: 80%;
  }
}
`;
