import React from "react";
import { Container } from "./AboutHeroElements";

const AboutHero = () => {
  return (
    <Container>
      <h2 className="head">
        About us
        <span></span>
      </h2>
      <p>
        Help today because tomorrow you may be the one who needs more helping!
      </p>
    </Container>
  );
};

export default AboutHero;
