import styled from "styled-components";
import bg from '../../assets/images/contact.jpg'

export const Container = styled.div`
  width: 100%;
  height: 300px;
  background:linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.7)), url(${bg});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .head {
    font-size: 2.4rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
  }

  .head span {
     width: 100px;height: 2px;
     background-color: #fff;
  }

  .head::after {
    content: '';
    width: 300px;
    height: 1px;
    background-color: #fff;
    margin-top: -2px;
  }

  p {
    color: #fff;
    font-size: 1.4rem;
  }

  @media screen and (max-width: 840px) {
    
    p {
        font-size: 1rem;
    }
  }

  @media screen and (max-width: 430px) {
    .head {
        font-size: 2rem;
    }

    .head::after {
        width: 200px;
    }

    p {
        width: 90%;
    }
  }

  @media screen and (max-width: 320px) {
    .head::after {
        width: 150px;
    }
  }
`;
