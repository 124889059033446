import styled from "styled-components";

export const SideWrapper = styled.div`
 min-height: 100vh;
 background: rgb(3, 77, 138);
 width: 25%;
position: absolute;
left: 0;
top: 0;

 .header{
    text-align: start;
    padding: 15px 2px;
    color: #f1f1f1;
    box-shadow: 0 1rem .5rem rgba(5, 5, 5, 0.103);
    display: flex;
    align-items: center;
    justify-content: space-between;
    .bars{
        font-size: 2rem;
        cursor: pointer;
        margin-right: 10px;
    }
 }

 .list{
    text-align: start;
    padding: 15px 5px;
    color: #f1f1f1;
    cursor: pointer;
    background-color: rgb(3, 100, 165);
    box-shadow: 0 1rem .5rem rgba(5, 5, 5, 0.103);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
    transition: transform 2s ease-in-out;
    .bars{
        font-size: 2rem;
        cursor: pointer;
        margin-right: 10px;
    }

    &:active{
        transform: scale(0.07);
    }
 }
 .lis{
    text-align: start;
    padding: 15px 5px;
    color: #f1f1f1;
    cursor: pointer;
    background-color: green;
    box-shadow: 0 1rem .5rem rgba(5, 5, 5, 0.103);
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.3rem;
    .bars{
        font-size: 2rem;
        cursor: pointer;
        margin-right: 10px;
    }
    &:active{
        transform: scale(0.07);
    }
 }
`
