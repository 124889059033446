import React from 'react'
import {  FaHistory } from 'react-icons/fa'
import { AiOutlineTeam } from 'react-icons/ai'
import {Container} from './AboutElements'
import logo3 from '../../assets/images/icons/our-mission-icon.png'


const About = () => {
  return (
    <Container>
        <div className="card">
             <img src={logo3} alt="logo" />
            <h2>Our mission</h2>
            <p>To make a positive impact in the lives of the elderly and children in our communities by offering them adequate health education sustainable and health assistance through volunteerism in order to strengthen their lives for healthy living..</p>
        </div>
        <div className="card">
        <FaHistory className='icon' />
            <h2>Our Vision</h2>
            <p>To ensure that the aged and children in our communities obtain sustainable health education and assistance without constraints. </p>
    
        </div>
        <div className="card">
        <AiOutlineTeam className='icon'/>
            <h2>Our Team</h2>
            <p>Our amazing team of regulars and part-time volunteers are committed to helping others. We take our convictions and turn them into action. Think you would be a good fit? Get in touch for more information! </p>

        </div>
            
    </Container>
  )
}

export default About
