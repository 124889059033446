import img1 from '../assets/images/gallery/img1.jpg'
import img2 from '../assets/images/gallery/img2.jpg'
import img3 from '../assets/images/gallery/img3.jpg'
import img4 from '../assets/images/gallery/img4.jpg'
import img5 from '../assets/images/gallery/img5.jpg'
import img6 from '../assets/images/gallery/img6.jpg'
import img7 from '../assets/images/gallery/img7.jpg'
import img8 from '../assets/images/gallery/img8.jpg'
import img9 from '../assets/images/gallery/img9.jpg'
import img10 from '../assets/images/gallery/img10.jpg'
import img11 from '../assets/images/gallery/img11.jpg'
import img12 from '../assets/images/gallery/img12.jpg'
import img13 from '../assets/images/gallery/img13.jpg'
import img14 from '../assets/images/gallery/img14.jpg'

const data = [
    {
        id: 1,
        ImgSrc: img1,
        txt: "A TROPHY TO THE WINNING SCHOOL; BADU D/A JHS"
    },
    {
        id: 2,
        ImgSrc: img2,
        txt: "PRESENTATION OF CERTIFICATE TO ALL SCHOOLS THAT PARTICIPATED "
    },
    {
        id: 4,
        ImgSrc: img4,
    },
    {
        id: 3,
        ImgSrc: img3,
    },
   
    {
        id: 5,
        ImgSrc: img5,
        txt: "AWARDING THE WINNING SCHOOL"
    },
    {
        id: 6,
        ImgSrc: img6,
        txt: "SPEECH GIVING BEFORE THE AWARD WAS PRESENTED TO THE WINNING SCHOOL"
    },
    {
        id: 7,
        ImgSrc: img7,
        txt: "PRESENTING LIBRARY BOXES TO ALL 16 SCHOOLS THAT PARTICIPATED "
    },
    {
        id: 8,
        ImgSrc: img8,
    },
    {
        id: 9,
        ImgSrc: img9,
    },
    {
        id: 10,
        ImgSrc: img10,
    },
    {
        id: 11,
        ImgSrc: img11,
    },
    {
        id: 12,
        ImgSrc: img12,
    },
    {
        id: 13,
        ImgSrc: img13,
    },
    {
        id: 14,
        ImgSrc: img14,
        txt: "BONO REGIONAL PRESIDENT PRESENTS A TROPHY TO THE WINNING SCHOOL; BADU D/A JHS "
    },
 
]

export default data