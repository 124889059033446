import img from "../assets/images/decem.jpg";
import img2 from "../assets/images/decem2.jpg";
export const eventData = [
    {
        id: 1,
        imgSrc: img,
        title: 'RHAFI End of year party for kids',
        theme: 'The child deserve a christmas',
        date: '26th December, 2022',
        location: 'Rhohe orphanage, Adoato Adumanu Kumasi'
    }, 
    {
        id: 1,
        imgSrc: img2,
        title: 'Kids Party',
        theme: 'Christmas is a happy season for kids',
        date: '26th December, 2022',
        location: 'Rhohe orphanage, Adoato Adumanu Kumasi'
    }
]