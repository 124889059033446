import React from 'react'
import { Container } from './ContactHeroElements'

const ContactHero = () => {
  return (
    <Container>
        <h2 className="head">Contact Us <span></span> </h2>
        <p>Help today because tomorrow you may be the one who
needs more helping!</p>
    </Container>
  )
}

export default ContactHero