import React from 'react'
import { Container } from './FooterElements'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <Container>
      <span></span>
      <div className="wrapper">
        <div className="left"></div>
        <div className="center">
        <a href="https://www.facebook.com/rhafi.org" target="_blank" ><FaFacebook className='icons'/></a>

          <FaTwitter className='icon' />
          <FaInstagram className='icon' />
          <FaLinkedin className='icon' />
        </div>
        <div className="right"></div>
      </div>
      <span>
      <p> &copy; Copyright 2022. All Rights reserved </p>
      </span>
       
    </Container>
  )
}

export default Footer
