import styled from "styled-components"

export const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 1.5rem;
    padding: 40px 20px;
 

    .left {
     height: 100%;
    }

    .left img {
        width: 100%;
        height: 100%;
    }

    .right {
        padding: 0 10px;
    }

    h2 {
    font-size: 2.3rem;
    text-transform: uppercase;
    color: #1f76bd;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
    /* text-align: center; */
}

    .right p {
        margin: 10px;
        text-align: justify;
    }

    h2 span {
    border-bottom: 2px solid #1f76bd;
    width: 100px;
    /* margin: 10px auto 0px; */
}

h2::after {
    content: '';
    display: block;
    width: 300px;
    height: 1px;
    background-color: #1f76bd;
    /* margin: 0px auto; */
    color: #1f76bd;
    margin-top: -2px;
}


@media screen and (max-width: 884px) {
    grid-template-columns: 1fr;
    gap: 1rem;
    .left, .right {
     height: 100%;

     p{
        margin: 6px;
     }
    }
}

@media screen and (max-width: 320px) {
    h2 {
        font-size: 1.3rem;
    }

    h2 {
        span{
            width: 60px;
        }
        
    }

    h2::after {
        width: 150px;
    }
}
`