import styled from 'styled-components';
import bg from '../../assets/images/heading-bg.jpg';
export const Container = styled.div`
  background: linear-gradient(
      to top left,
      rgba(0, 0, 8, 0.548),
      rgba(0, 0, 15, 0.301)
    ),
    url(${bg});
  background-size: cover;
  background-position: center;
  height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h3,
  p {
    color: #fff;
    font-size: 2.4rem;
    text-align: center;
    margin: 20px;
  }

  p {
    font-size: 1rem;
    top: 70%;
    left: 35%;
  }

  h3 {
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  h3 span {
    border-bottom: 2px solid #1f76bd;
    width: 100px;
    margin: 10px auto 0px;
  }

  h3::after {
    content: '';
    display: block;
    width: 300px;
    height: 1px;
    background-color: #1f76bd;
    margin: 0px auto;
    color: #1f76bd;
    margin-top: -2px;
  }


  @media screen and (max-width: 430px) {
    height: 40vh;

    h3{
        font-size: 30px;
    }
  }
`;
