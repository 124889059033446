import styled from "styled-components";
import bg from "../../assets/images/about-bg.webp";
export const Container = styled.div`
background: linear-gradient(rgba(0, 0, 0, 0.507), rgba(0, 0, 0, 0.788)),url(${bg});
background-position: center;
background-size: cover;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 100%;
min-height: 40vh;

h2 {
    font-size: 2.3rem;
    text-transform: uppercase;
    color: #fff;
    display: flex;
    flex-direction: column;
    text-align: center;
}

p {
    color: #fff;
    font-size: 1.5rem;
    max-width: 700px;
    text-align: center;
    margin-top: 20px;
}

.head span {
    border-bottom: 2px solid #fff;
    width: 100px;
    margin: 10px auto 0px;
}

.head::after {
    content: '';
    display: block;
    width: 300px;
    height: 1px;
    background-color: #fff;
    margin: 0px auto;
    color: #1f76bd;
    margin-top: -2px;
}

@media screen and (max-width: 840px) {
    .head {
        left: 30%;
        font-size: 1.8rem;
    }

    p {
        font-size: 1.3rem;
        left: 15%;
    }
}

@media screen and (max-width: 430px) {
    .head {
        font-size: 1.3rem;
        left: 35%;
    }

    p {
        font-size: 1rem;
        left: 6%;
        width: 90%;
    }

    .head span {
       width: 60px;
    }

    .head::after {
        width: 150px;
    }
}

@media screen and (max-width: 320px) {
    .head {
      left: 25%;
    }

    .head span {
        width: 60px;
    }

    .head::after {
        width: 150px;
    }

    p {
        width: 80%;
        left: 15%;
    }
}
`