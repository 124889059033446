import styled from "styled-components";

export const Container = styled.div`
width: 100%;
height: 100vh;
padding: 20px 70px;
box-sizing: border-box;
display: flex;
justify-content: center;
align-items: center;

.swiper{
    display: flex;
    align-items: center;
    justify-content: center;
    

    .slider{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

.wrapper {
    display: grid;
    grid-template-columns: 60% 38%;
    gap: 2%;
    place-items: center;
    place-content: center;
    height: 80vh;
    border-radius: 25px;
    box-shadow: .5px .5px 16px 2px #a0a0a048;
    transition: all .5s ease;
}

.wrapper:hover {
    box-shadow: 1.5px 1.5px 32px 4px #a0a0a0a4;
    border: none;
    transition: all .5s ease;
}

.wrapper img {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    
}

img {
    width: 100%;
    height: 100%;
}


.content {
    flex: 1;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h3 {
    font-size: 2rem;
}

.icon {
    color: #ffae00;
    margin-right: 10px;
}

.date, .loc {
    font-size: 1.3rem;
    color: #8e8e8e;
    margin: 1rem 0px;
}

.about {
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 600;
}

button {
    background-color: #ffae00;
    border: none;
    color: #fff;
    padding: 16px 32px;
    border-radius: 6px;
    position: absolute;
    bottom: 1rem;
}

@media screen and (max-width: 840px) {

    .wrapper {
        width: 90%;
    }

    h3 {
        font-size: 1.3rem;
    }

    p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 430px) {
    padding: 5px 5px;
    height: 70vh;

    .wrapper {
        display: flex;
        flex-direction: column;
        height: 60vh;
    }

    img {
        width: 378px;
        height: 305px;
        
    }

    .wrapper img {
        border-bottom-left-radius: 0;
        border-top-right-radius: 25px;
    }

    h3 {
        font-size: 17px;
    }

    .icon {
    color: #ffae00;
    margin-right: 7px;
}

.date, .loc {
    font-size: 12px;
    color: #8e8e8e;
    margin: 5px 0px;
}

.about {
    font-size: 15px;
    line-height: 1.5;
    font-weight: 600;
}


}

@media screen and (max-width: 320px) {
    padding: 5px 5px;

    .wrapper {
        margin: 0;
    }

    img {
        width: 278px;
        height: 180px;
        border-bottom-left-radius: 0px;
    }

    h3 {
        font-size: 17px;
    }

    .icon {
    color: #ffae00;
    margin-right: 7px;
}

.date, .loc {
    font-size: 12px;
    color: #8e8e8e;
    margin: 5px 0px;
}

.about {
    font-size: 15px;
    line-height: 1.5;
    font-weight: 600;
}
}
`