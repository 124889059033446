import React from 'react';
import { FaAccusoft, FaArrowAltCircleDown, FaTextHeight } from 'react-icons/fa';
import { MdDashboard, MdEvent, MdPictureInPicture, MdUpdate } from 'react-icons/md';
import { SideWrapper } from './DashSideBarElement';
const DashSideBar = () => {
  return (
    <SideWrapper className="sideWrap">
      <div className="header">
        <h1>Admin@Rhafi</h1>
        <FaAccusoft className="bars" />
      </div>
      <div className="header" style={{cursor: 'pointer'}}>
        <h1>Dashboard</h1>
        <MdDashboard className="bars" />
      </div>
      <div className="list">
        <h3>Add Gallery</h3>
        <MdPictureInPicture className="bars" />
      </div>
      <div className="list">
        <h3>Add Upcoming Event</h3>
        <MdEvent className="bars" />
      </div>
      <div className="list">
        <h3>Add Hero Caption</h3>
        <FaTextHeight className="bars" />
      </div>
      <div className="list add">
        <h2>Read & Update</h2>
        <FaArrowAltCircleDown className="bars" />
      </div>
      <div className="lis">
        <h3>Update Event</h3>
        <MdUpdate className="bars" />
      </div>
      <div className="lis">
        <h3>Update Hero Caption</h3>
        <FaTextHeight className="bars" />
      </div>
    </SideWrapper>
  );
};

export default DashSideBar;
