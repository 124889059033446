import React from 'react'
import EventsCard from '../components/EventsCard/EventsCard'
import EventsHero from '../components/EventsHero/EventsHero'
import Footer from '../components/Footer/Footer'
import Join from '../components/Join/Join'
import Navbar from '../components/Navbar/Navbar'
import Recent from '../components/Recent/Recent'

const Events = () => {
  return (
    <>
    <Navbar />
    <EventsHero />
    <EventsCard />
    <Join />
      <Recent />
      <Footer />
    </>
  )
}

export default Events