import React from 'react'
import { FaUserCircle } from 'react-icons/fa'
import styled from 'styled-components'
import Logo from '../../assets/images/logo.png'
const DashNav = () => {
  return (
      <DashNavWrapper>
          <LogoWrap>
          <img src={Logo} alt="rhafi-logo" />
           </LogoWrap>
          <AvatarWrap>
            <p>Rhafi</p> 
            <FaUserCircle className='user' />
          </AvatarWrap>
      </DashNavWrapper>
  )
}

const DashNavWrapper = styled.nav`
    position: absolute;
    top: 0;
    left: 25%;
    right: 0;
    background: rgb(3, 77, 138);
    width: calc(100% - 25%);
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
`

const AvatarWrap = styled.div`
 display: flex;
 align-items: center;
 justify-content: center;
 gap: 5px;
 .user{
    font-size: 30px;
    color: #fff;
 }
 p{
    font-size: 15px;
    color: yellow;
    font-weight: 600;
 }
`
const LogoWrap = styled.div`
cursor: pointer;
 width: 5%;
 padding-left: 10px;
 img{
    width: 100%;
 }
`

export default DashNav