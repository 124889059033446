import React from 'react'
import styled from 'styled-components'
import AddGallery from '../AddGallery/AddGallery'

const DashContent = () => {
  return (
    <DashContentWrap>
      <AddGallery />  
    </DashContentWrap>
  )
}


const DashContentWrap = styled.section`
  width: calc(100% - 25%);
  min-height: calc(100vh - 4.5rem);
  background: #f1f1f1;
  position: absolute;
  left: 25%;
  top: 4.5rem;
  right: 0;
`

export default DashContent