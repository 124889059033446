import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  /* background-color: #fff; */
  background-color: #1f76bd;
  padding: 0px 90px;
  box-sizing: border-box;
  transition: all 0.8s ease-in-out;
  box-shadow: 1px 1px 15px #00000080;

  .wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    transition: all 0.8s ease-in-out;
  }

  .logo-div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-div .logo {
    display: flex;
    align-items: center;
  }

  .logo-div .logo img {
    width: 50px;
    height: 50px;
    margin: 10px 0 10px 30px;
  }

  ul {
    display: flex;
    align-items: center;
  }

  ul li {
    list-style: none;
  }

  ul li a {
    text-decoration: none;
    color: #fff;
    /* color: #1f76bd; */
    padding: 12px 12px;
    font-size: 17px;
    text-transform: capitalize;
    transition: all 0.8s ease-in;
    font-weight: 500;
  }

  ul li a:active::after,
  ul li a:hover::after,
  ul li a:focus::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 60px;
    height: 4px;
    margin: 0px auto;
    background-color: #fff;
    transition: all 0.8s ease-in;
    margin-left: 10px;
  }

  .res {
    color: #fff;
    font-size: 1.3rem;
    transition: all 0.8s ease-in-out;
    display: none;
    position: absolute;
    right: 20px;
    font-weight: 700;
  }

  .wrapper button {
    cursor: pointer;
    outline: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 6px 12px;
    font-size: 1.4rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #fff;
    border-color: #fff;
    :hover {
      color: #fff;
      background-color: #0d6efd;
      border-color: #0d6efd;
    }
  }

  .responsive button {
      width: 50%;
      padding: 16px 0px;
      box-sizing: border-box;
      border: none;
      margin-bottom: 1rem;
      font-size: 17px;
    }

  @media screen and (max-width: 430px) {
    padding: 0px;

    .logo-div .logo img {
      width: 50px;
      height: 50px;
    }

    .wrapper button {
      display: none;
    }

    .links {
      display: none;
    }

    .res {
      display: block;
      z-index: 1;
      position: sticky;
      left: 0;
      z-index: 999;
    }

    .responsive {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .responsive ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }

    .responsive ul li {
      margin: 5px 40px 5px 0;
    }

    .responsive ul li a {
      padding: 10px 60px;
      margin: 5px;
    }

    .responsive button {
      width: 50%;
      padding: 16px 0px;
      box-sizing: border-box;
      border: none;
      margin-bottom: 1rem;
      font-size: 17px;
    }

    ul li a:active::after,
    ul li a:hover::after,
    ul li a:focus::after {
      width: 0;
      height: 0px;
    }
  }

  @media screen and (max-width: 320px) {
    .responsive button {
      width: 30%;
      padding: 8px 0px;
      box-sizing: border-box;
      border: none;
      margin-bottom: 1rem;
      font-size: 17px;
    }
  }
`;

export const Links = styled(Link)``;
