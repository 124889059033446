import React from 'react';
import { Container } from './JoinElements';
import { useNavigate } from 'react-router-dom';

const Join = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <h3>
        Ready to Join With Us? <span></span>
      </h3>
      <p>
        The secret to happiness lies in helping others. Never underestimate the
        difference the abused and the helpless
      </p>
      <button
        onClick={() => {
          navigate('/contact-us');
        }}>
        Join Us
      </button>
    </Container>
  );
};

export default Join;
