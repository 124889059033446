import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .left,
  .right {
    height: 80vh;
    margin: 10px;
    position: relative;
  }

  .left img,
  .right img {
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
  }

  .left .text,
  .right .text {
    position: absolute;
    width: 100%;
    height: 100%;
    color: #fff;
  }

  .left .head,
  .right .head {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    box-sizing: border-box;
    position: absolute;
    bottom: 0;
    background-color: #1f76bdc9;
  }

  .left .head h2,
  .left .head h4,
  .right .head h2 {
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
  }

  .left .head h4,
  .right .head h4 {
    font-size: 1.2rem;
    margin-top: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }

  .left .text p,
  .right .text p {
    padding: 30px;
    letter-spacing: 1.3px;
    display: none;
    transition: all 0.8s ease;
    height: 73%;
    line-height: 1.2;
    background-color: #1f76bdc9;
    color: #fff;
  }

  .left:hover .text p,
  .right:hover .text p {
    display: block;
    transition: all 0.8s ease !important;
  }

  .left:hover .text .head,
  .right:hover .text .head {
    border-top: 1px solid #fff;
    transition: all 0.8s ease;
  }

  @media screen and (max-width: 430px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    .left:hover .text p,
    .right:hover .text p {
      display: none;
      transition: all 0.8s ease !important;
    }

    .left .head h2,
    .left .head h4,
    .right .head h2,
    .right .head h4 {
      font-size: 1.5rem;
    }

    .left .head h4,
    .right .head .h4 {
      font-size: 20px;
    }
  }
`;
