import React from 'react'
import { Container } from './RecentCardElements'
import img from '../../assets/images/recent.webp'
import { FaClock } from 'react-icons/fa'

const RecentCard = () => {
  return (
    <Container>
      <img src={img} alt="img" />
      <div className="text">
      <p className="date"> <FaClock className='icon' /> 20th April, 2020 </p>
      <h3>End of year party for kids</h3>
      <p className='brief' >Theme: The kids deserve a christmas</p>

      {/* <button>Read More</button> */}
      </div>
      
    </Container>
  )
}

export default RecentCard