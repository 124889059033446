import React, { useState } from 'react';
import galleryData from '../utils/galleryData';
import { Container, Head } from './GalleryElements';
import { MdClose } from 'react-icons/md';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';

const Gallery = () => {
  const [model, setModel] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState('');

  const getImg = (ImgSrc) => {
    setTempImgSrc(ImgSrc);
    setModel(true);
  };

  return (
    <>
      <Navbar />
      <Container>
        <Head>
          RHAFI Reading Clubs Quiz Competition For Schools In The Badu Township
          Of The Bono Region <span></span>{' '}
        </Head>

        <div className={model ? 'model open' : 'model'}>
          <img src={tempImgSrc} alt="img" />
          <MdClose
            className="svg"
            onClick={() => {
              setModel(false);
            }}
          />
        </div>

        {!model ? (
          <div className="gallery">
            {galleryData &&
              galleryData.map((item) => {
                return (
                  <div
                    className="pics"
                    key={item.id}
                    onClick={() => getImg(item.ImgSrc)}>
                    <img
                      src={item.ImgSrc}
                      alt="img"
                      style={{ width: '100%' }}
                    />
                    <div className="txt">
                      <p> {item.txt} </p>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : (
          ''
        )}

        {/* <Head>
        RHAFI Reading Clubs Quiz Competition For Schools In The Badu Township Of The Bono Region <span></span>{" "}
      </Head>

      { !model ? <div className="gallery">
        {data.map((item, index) => {
          return (
            <div className="pics" key={index} onClick={()=> getImg(item.ImgSrc)} >
              <img src={item.ImgSrc} alt="img" style={{width: '100%'}} />
              <div className="txt">
              <p> {item.txt} </p>
              </div>
             
            </div>
          );
        })}
      </div> : "" } */}
      </Container>
      <Footer />
    </>
  );
};

export default Gallery;
