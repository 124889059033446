import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 40px 20px;
  .card {
    width: 100%;
    height: 425px;
    background-image: linear-gradient(
      160deg,
      #115b9b -25%,
      #1290f8 50%,
      #115b9b 125%
    );
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 5px;

    :nth-child(3){
        margin-left: 50%;
    }
    h2 {
      font-size: 1.4rem;
      color: #fff;
      text-transform: uppercase;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    .icon {
      font-size: 4rem !important;
      color: #fff;
    }
    p {
      color: #fff;
      letter-spacing: 1.5px;
      text-align: center;
      font-size: 16px;
    }
  }


  @media screen and (max-width: 430px) {
    grid-template-columns: 1fr;

    .card{
        :nth-child(3){
        margin-left: 0;
    }
    }
  }

  @media screen and (max-width: 320px) {
    .card {
      width: 95%;
      padding: 2px;
    }
  }
`;
