import React from 'react'
import ContactCard from '../components/ContactCard/ContactCard'
import ContactHero from '../components/ContactHero/ContactHero'
import Footer from '../components/Footer/Footer'
import Navbar from '../components/Navbar/Navbar'

const ContactUs = () => {
  return (
    <>
    <Navbar />
     <ContactHero />
      <ContactCard />
    <Footer />
    </>
  )
}

export default ContactUs