import React from 'react';
import { Container } from './EventsCardElements';
import { FaCalendar, FaLocationArrow } from 'react-icons/fa';
import { Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { eventData } from '../../utils/eventData';

const EventsCard = () => {
  return (
    <Container>
      <Swiper
        className="swiper"
        modules={[Navigation, Autoplay]}
        navigation={true}
        spaceBetween={40}
        slidesPerView={1}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}>
          {eventData &&
          eventData.map((event) => (
            <SwiperSlide className="slider" key={event.id}>
              <div className="wrapper">
                <img src={event.imgSrc} alt={event.title} />
                <div className="content">
                  <h3>{event.title}</h3>
                  <p className="date">
                    <FaCalendar className="icon" />
                    {event.date}
                  </p>
                  <p className="loc">
                    <FaLocationArrow className="icon" />
                    {event.location}
                  </p>
                  <p className="about">Theme: {event.theme}</p>
                  {/* <button>Read More</button> */}
                </div>
              </div>
              </SwiperSlide>
            ))}
      </Swiper>
    </Container>
  );
};

export default EventsCard;
