import React from "react";
import { Container } from "./CareElements";
import img1 from "../../assets/images/hero1.jpg";
import img2 from "../../assets/images/hero2.jpg";

const Care = () => {
  return (
    <Container>
      <div className="left">
        <img src={img1} alt="img" />
        <div className="text">
          <div className="head">
            <h2>We care about others</h2>
            <h4>we are humans</h4>
          </div>
        </div>
      </div>
      <div className="right">
        <img src={img2} alt="img" />
        <div className="text">
          <div className="head">
            <h2>we fight together</h2>
            <h4>we are humans</h4>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Care;
