import React from 'react'
import { Container, Wrapper } from './DonateElements'
// import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import ContactCard from '../components/ContactCard/ContactCard';

const Donate = () => {
  // const [amount, setAmount] = useState(1)
  return (
    <>
      <Navbar />
      <Container>
      <Wrapper>
      {/* <input type="number" onChange={(e) => {
         setAmount(e.target.value)

      }} /> */}
      <p style={{textAlign:"center", marginTop:"50px"}} >You can contact any of the contacts below</p>
      
      <ContactCard />
      {/* <PayPalScriptProvider
      options={{ "client-id": "ATuWXoRKvhj7--tEnjtnucDljV2ewLZ0qD_5zWGJGfukbFJc8W2wyiqTM_TnXbwivLFIFPE6hRGAYkZx" }}
      >
        <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: amount,
                },
              },
            ],
          });
        }}
        onApprove={async (data, actions) => {
          const details = await actions.order.capture();
          const name = details.payer.name.given_name;
          alert("Transaction completed by " + name);
        }}
        />
      </PayPalScriptProvider> */}
      </Wrapper>
    </Container>
      <Footer />
    </>
  )
}

export default Donate