import React from 'react'
import AboutHero from '../components/AboutHero/AboutHero'
import About from '../components/About/About'
import AboutContent from '../components/AboutContent/AboutContent'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'

const MainAbout = () => {
  return (
    <>
      <Navbar />
    <AboutHero />
    <AboutContent />
      <About />
      <Footer />
    </>
  )
}

export default MainAbout
