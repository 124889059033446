import React from "react";
import { Container } from './EventsHeroElements'

const EventsHero = () => {
  return (
    <Container>
      <h3>Upcoming Events <span></span> </h3>
      <p>
        Help today because tomorrow you may be the one who needs more helping!
      </p>
    </Container>
  );
};

export default EventsHero;
