import React from 'react'
import styled from 'styled-components'
import DashContent from '../../components/DashContent/DashContent'
import DashNav from '../../components/DashNav/DashNav'
import DashSideBar from '../../components/DashSideBar/DashSideBar'

const DashboardPage = () => {
  return (
    <Dash>
      <DashSideBar />
      <DashNav />
      <DashContent />
    </Dash>
  )
}

const Dash = styled.section`
 display: flex;
 align-items: center;
 justify-content: center;
 position: relative;
`
export default DashboardPage