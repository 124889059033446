import bg1 from '../../assets/images/hero1.jpg'
import bg2 from '../../assets/images/decem.jpg'
import bg3 from '../../assets/images/hero3.jpg'

export default [
    {
        title: "Because they need your help",
        description: "DO NOT LET THEM DOWN",
        src: bg1,
        btn: "Donate Now"
    },
    
    {
        title: "RHOHE End Of Year Party For Kids",
        description: "Theme: The kids deserve a christmas",
        src: bg2,
        btn: "Donate Now"
    },
    
    {
        title: "A PENNY IS A LOT OF MONEY, IF YOU HAVE NOT GOT A PENNY.",
        description: "YOU CAN MAKE THE DIFFRENCE !",
        src: bg3,
        btn: "Donate Now"
    },
    
]