import React from 'react'
import About from '../components/About/About'
import Care from '../components/Care/Care'
import Footer from '../components/Footer/Footer'
import Slider from '../components/Hero/Slider'
import Navbar from '../components/Navbar/Navbar'

const Home = () => {
  return (
    <>
    <Navbar />
    <Slider />
    <About />
    <Care />
    <Footer />
    </>
  )
}

export default Home