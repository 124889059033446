import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 70px;
  box-sizing: border-box;
  width: 100%;
  height: fit-content;

  .gallery {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0px 12px;
    margin-top: 3rem;
  }

  .gallery .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 12px;
    position: relative;
  }

  .gallery .pics:hover {
    filter: opacity(.8);
  }

  .txt {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    /* padding: 20px; */
    box-sizing: border-box;
  }

  .txt p {
    box-sizing: border-box;
    background-color: #1f76bdc9;
    color: #fff;
    font-weight: 400;
    width: 100%;
    /* height: 50px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 840px) {
    .gallery {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
  }

  @media screen and (max-width: 430px) {
    padding: 10px 10px;

    .gallery {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
    }

    .txt p {
    font-weight: 200;
  }
  }

  @media screen and (max-width: 320px) {
    padding: 10px 10px;
    .gallery {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
      -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
    }
  }

  /* model  */

  .model {
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0px 20px;
  margin: 0px auto;
}

.model svg {
  position: fixed;
  right: 10px;
  top: 10px;
  color: #fff;
  font-size: 2rem;
  padding: 5px;
  background-color: #115c9b;
}
`;

export const Head = styled.h2`
  text-align: center;
  font-size: 2rem;
  color: #1f76bd;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  margin: 0 auto;


  span {
    width: 100px;
    height: 3px;
    background-color: #1f76bd;
  }

  ::after {
    content: "";
    width: 300px;
    height: 2px;
    background-color: #1f76bd;
    margin-top: -4px;
  }

  @media screen and (max-width: 430px) {
    font-size: 1.2rem;
    line-height: 1;
    width: 90%;

    span {
    width: 90px;
    height: 3px;
    background-color: #1f76bd;
  }

  ::after {
    content: "";
    width: 200px;
    height: 2px;
    background-color: #1f76bd;
    margin-top: -4px;
  }
  }
`;
