import styled from "styled-components";
import bg from '../../assets/images/join-bg.jpg'
export const Container = styled.div`
background: linear-gradient(rgba(0, 0, 3, 0.733), rgba(0, 0, 10, 0.753)),url(${bg});
background-position: center;
background-size: cover;
width: 100%;
height: 390px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 2rem;

h3, p, button {
    text-align: center;
    color: #fff;
    font-size: 2.4rem;
}

h3 {
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    text-align: center;
}

h3 span {
    border-bottom: 2px solid #1f76bd;
    width: 100px;
    margin: 10px auto 0px;
}

h3::after {
    content: '';
    display: block;
    width: 400px;
    height: 1px;
    background-color: #1f76bd;
    margin: 0px auto;
    color: #1f76bd;
    margin-top: -2px;
}

p {
    top: 40%;
    font-size: 1.4rem;
    left: 30%;
    width: 50%;
}

button {
    padding: 16px 32px;
    border: none;
    background-color: #ffae00;
    font-size: 1.5rem;
    color: #fff;
    top: 60%;
    left: 45%;
    transition: all .8s ease-in-out;
}

button:hover {
    border-radius: 10px;
    background-color: #fff;
    color: #ffae00;
    transition: all .8s ease-in-out;
}

@media screen and (max-width: 840px) {
    h3 {
        left: 20%;
        font-size: 2rem;
    }

    h3::after {
        width: 400px;
    }

    p {
        font-size: 1.3rem;
        width: 70%;
        left: 15%;
    }

    button {
        left: 40%;
        
    }
}

@media screen and (max-width: 430px) {
    h3 {
        font-size: 1.3rem;
        left: 10%;
    }

    h3::after {
        width: 250px;
    }

    p {
        font-size: 1rem;
    }

    button {
        padding: 10px 25px;
        left: 37%;
        top: 70%;
    }
}

@media screen and (max-width: 320px) {
    h3 span {
        width: 60px;
    }

    h3::after {
        width: 150px;
    }

    p {
        width: 80%;
    }

    button {
        left: 31%;
    }
}
`