import React from 'react'
// import FirstNav from '../FirstNav/FirstNav'
import MainNav from '../MainNav/MainNav'
import './NavbarElements.css'

const Navbar = () => {
  return (
    <div className='nav' >
        {/* <FirstNav /> */}
        <MainNav />
    </div>
  )
}

export default Navbar